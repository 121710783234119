import clsx from 'clsx';
import './styles.scss';

export interface LoadingProps {
  className?: string;
  isLoading?: boolean;
  children: any;
}
export default function Loading(props: LoadingProps) {
  const { isLoading = false, className, children } = props;
  return (
    <>
      {isLoading && (
        <div
          className={clsx([
            'w-full h-full flex items-center justify-center bg-backdropWhite transform transition duration-150 ease-in-out',
            className,
          ])}
        >
          <div className="loading-wrapper">
            <div className="loading">
              <div /><div /><div /><div /><div />
            </div>
          </div>
        </div>
      )}

      {!isLoading && children}
    </>
  );
}
