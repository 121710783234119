import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from './components/Loading';

const Shippinglabel = lazy(() => import('./pages/shippinglabel').then(module => ({ default: module.Shippinglabel })));
const NotFound = lazy(() => import('./pages/404').then(module => ({ default: module.NotFound })));

function App() {
  return (
    <Suspense fallback={<Loading>{''}</Loading>}>
      <Routes>
        <Route path="/" element={<Shippinglabel />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
